exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pickomino-src-index-tsx": () => import("./../../../content/pickomino/src/index.tsx" /* webpackChunkName: "component---content-pickomino-src-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-academic-tsx": () => import("./../../../src/pages/academic.tsx" /* webpackChunkName: "component---src-pages-academic-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-utmb-gui-tsx": () => import("./../../../src/pages/utmb-gui.tsx" /* webpackChunkName: "component---src-pages-utmb-gui-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-preview-tsx": () => import("./../../../src/templates/blog-preview.tsx" /* webpackChunkName: "component---src-templates-blog-preview-tsx" */),
  "component---src-templates-map-tsx": () => import("./../../../src/templates/map.tsx" /* webpackChunkName: "component---src-templates-map-tsx" */)
}

